import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Redirect } from "react-router"
import _ from "lodash"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
  FormGroup,
} from "reactstrap"
import {
  sendOTP,
  sendEmailOTP,
  verifyOTP,
} from "../../clientServices/authService"
import AuthCode from "react-auth-code-input"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { Button } from "antd"
import { setCurrentUser } from "appRedux/slices/currentUser"

const PhoneVerificationForm = props => {
  const dispatch = useDispatch()
  const [sendOTPtoEmail, setSendOTPtoEmail] = useState(false)
  const [phone, setPhone] = useState("")
  const [otpDisable, setOtpDisable] = useState(true)
  const [otp, setOtp] = useState("")
  const [redirect, setRedirect] = useState(false)

  const { loading, currentUser } = useSelector(state => state.currentUser)

  useEffect(() => {
    if (currentUser?.data?.otpVerified) {
      setRedirect(true)
      toastr.info("User Already Verified...", "")
    }
  }, [])

  const _sendOtp = async () => {
    if (phone.length !== 10) {
      toastr.warning("Phone number should be 10 digits only", "")
    } else {
      toastr.info("Action in progress...", "")
      const response = sendOTPtoEmail
        ? await sendEmailOTP(phone, currentUser.email)
        : await sendOTP(phone)
      if (response.status && response.status.code === 200) {
        toastr.success("OTP Sent", "")
        setOtpDisable(false)
      } else {
        toastr.error(
          _.get(response, "data.message") ||
            response.message ||
            "Otp sending failed",
          ""
        )
      }
    }
  }

  const _onSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()
    if (phone.length < 10) {
      toastr.error("Phone number should be 10 digits", "")
      return
    }
    if (phone && otp) {
      toastr.info("Action in progress...", "")
      const response = await verifyOTP(phone, otp)
      if (response && response.message === "OTP Verification successful") {
        let updatedUser = _.cloneDeep(currentUser)
        updatedUser.data.otpVerified = true
        dispatch(setCurrentUser({ data: updatedUser }))
        setRedirect(true)
        toastr.success("User Verified", "")
      } else {
        toastr.error(
          _.get(response, "data.message") ||
            response.message ||
            "OTP verification failed",
          ""
        )
      }
    }
  }

  const _otpScreen = () => {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verify your phone</h4>
                        <p className="mb-5">
                          Please enter the 6 digit code sent to{" "}
                          <span className="font-weight-semibold">
                            {`XXXXXXX${
                              phone ? phone.slice(7, phone.length) : "xxx"
                            }`}
                          </span>
                        </p>

                        <Form>
                          <Row>
                            <Col xs={12}>
                              <FormGroup className="verification">
                                <label
                                  htmlFor="digit1-input"
                                  className="sr-only"
                                >
                                  Dight 1
                                </label>
                                <AuthCode
                                  characters={6}
                                  className="form-control form-control-lg text-center"
                                  allowedCharacters="^[0-9]"
                                  inputStyle={{
                                    width: "60px",
                                    height: "36px",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    marginRight: "8px",
                                    border: "1px solid #ced4da",
                                    textTransform: "uppercase",
                                  }}
                                  onChange={val => setOtp(val)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <div className="mt-4">
                            <button
                              className="btn btn-success w-md"
                              type="submit"
                              onClick={_onSubmit}
                              disabled={otp.length !== 6}
                              onDoubleClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                return
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  const _mobile = () => {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary"> Verify User</h5>
                        </div>
                      </Col>
                      <Col xs={5} className="align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </div>

                    <div className="p-2">
                      <AvForm className="form-horizontal">
                        <div className="mb-3">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+91</InputGroupText>
                            </InputGroupAddon>
                            <div style={{ width: "87%" }}>
                              <AvField
                                name="telephoneProp"
                                type="text"
                                validate={{ tel: true }}
                                required
                                onChange={e => {
                                  setPhone(e.target.value)
                                }}
                              />
                            </div>
                          </InputGroup>
                        </div>
                        <div className="text-start mb-4">
                          <Input
                            type="checkbox"
                            onChange={() => setSendOTPtoEmail(!sendOTPtoEmail)}
                          />{" "}
                          Send OTP to Email
                        </div>
                        <div className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                            onClick={_sendOtp}
                            disabled={loading}
                            onDoubleClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                              return
                            }}
                          >
                            Get OTP
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {otpDisable ? _mobile() : _otpScreen()}
      {redirect &&
        (currentUser.data.jotUserType === 4 ? (
          currentUser.data.permissions.includes("TAG_CONTENT") ? (
            <Redirect to="/tasks/content/tag" />
          ) : currentUser.data.permissions.includes("MANAGE_CURATION") ? (
            <Redirect to="/feed/curation" />
          ) : (
            <Redirect to="/user/verify" />
          )
        ) : window?.prev_location?.pathname ? (
          <Redirect
            to={
              window?.prev_location?.pathname + window?.prev_location?.search ||
              ""
            }
          />
        ) : currentUser.data.jotUserType === 60 ? (
          <Redirect to="/agency/stats" />
        ) : (
          <Redirect to="/content/browse" />
        ))}
    </React.Fragment>
  )
}

export default PhoneVerificationForm
