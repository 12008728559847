import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const getLiveCallingUserData = async payload => {
  try {
    let data = await Axios.post(`/api/liveCalling/list`, payload)
    return data.data
  } catch (error) {
    throw error
  }
}
export const getLiveCallingLogData = async payload => {
  try {
    let data = await Axios.post(`/api/liveCalling/creator/logs`, payload)
    return data.data
  } catch (error) {
    throw error
  }
}

export const uploadFile = async payload => {
  try {
    let { data } = await Axios.post("/api/liveCalling/uploadFile", payload)
    toastr.success("File uploaded Successfully")
    return data
  } catch (error) {
    throw error
  }
}
export const upsertCalling = async payload => {
  try {
    let data = await Axios.post("/api/liveCalling/upsert", payload)
    toastr.success("Creator Updated Successfully")
  } catch (error) {
    throw error
  }
}
export const updateStatus = async payload => {
  console.log("🚀 ~ updateStatus ~ payload:", payload)
  try {
    let data = await Axios.post("/api/liveCalling/creator/status", payload)
    toastr.success("Status Updated Successfully")
  } catch (error) {
    throw error
  }
}
export const agencyList = async payload => {
  console.log("🚀 ~ updateStatus ~ payload:", payload)
  try {
    let data = await Axios.post("/api/liveCalling/agency", payload)
    return data.data
  } catch (error) {
    throw error
  }
}

export const diamondsByUsers = async payload => {
  try {
    let data = await Axios.post(`/api/liveCalling/diamondsByUser`, payload)
    return data.data
  } catch (error) {
    throw error
  }
}
export const totaldurationbyuseruuid = async payload => {
  try {
    let data = await Axios.post(`/api/liveCalling/getStats`, payload)
    return data.data.data
  } catch (error) {
    throw error
  }
}
