import Axios from "axios"
import QueryString from "query-string"
import toastr from "toastr"

export const getStoriesData = async payload => {
  try {
    const offset = payload?.filters["currentPage"]
      ? payload?.filters["currentPage"] - 1
      : 0
    const limit = payload?.filters["pageSize"]
      ? payload?.filters["pageSize"]
      : 10
    let { data } = await Axios.post(
      `/api/audioStories/get?offset=${offset}&limit=${limit}`,
      payload
    )
    return { data: data.result.data, total: data.result.total.value }
  } catch (error) {
    throw error
  }
}

export const upsertStory = async payload => {
  try {
    toastr.info("Audio Story Create / Update in Progress...")
    let data = await Axios.post(`/api/audioStories/upsert`, payload)
    toastr.clear()
    toastr.success("Audio Story Created / Updated Successfully...")
    return data.data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}

export const upsertSeries = async payload => {
  try {
    let data = await Axios.post(`/api/audio-series/upsert`, payload)
    toastr.success("Series Updated Successfully")
    return data.data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}

export const getAudioStoryCollectionData = async payload => {
  try {
    let data = await Axios.post(`/api/audioStoryCollection/get`, payload)
    return data.data.result.data
  } catch (error) {
    throw error
  }
}

export const getSeriesData = async payload => {
  try {
    let data = await Axios.post(`/api/audioSeries/get`, payload)
    return data.data.result.data
  } catch (error) {
    throw error
  }
}

export const getCategories = async () => {
  try {
    let { data } = await Axios.get(`/api/audio-series/categories`)
    return data.data
  } catch (error) {
    throw error
  }
}

export const upsertCategory = async (payload, action) => {
  try {
    let { data } = await Axios.post(
      `/api/audio-series/categories?action=${action}`,
      payload
    )
    toastr.success("Action successful...")
    return data.data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}

export const upsertStoryCollection = async payload => {
  try {
    let data = await Axios.post(`/api/audioStoryCollection/upsert`, payload)
    toastr.success("Collection Updated Successfully")
    return data.data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}
