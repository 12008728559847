import { Button } from "@mui/material"
import { Form, Input, Select, Divider, Switch } from "antd"
import { getLocationData } from "appRedux/slices/location"
import { RootState } from "appRedux/store/configureStore"
import {
  agencyList,
  getLiveCallingUserData,
  uploadFile,
  upsertCalling,
} from "clientServices/liveCallingService"
import { Language, genderOptions, languageOptions } from "constants/uiConstants"
import React, { ChangeEvent, useEffect, useState } from "react"
import { MdOutlineAdd } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router-dom"
import { Card, Row, Col, CardBody } from "reactstrap"
import toastr from "toastr"
import { capitalizeFirstLetter } from "util/common"

interface Props extends RouteComponentProps {
  item: { user_uuid: string; profile_pic: string; user_name: string }
  queryParams: { [key: string]: string | number }
}

type FormData = {
  user_uuid: string
  user_name: string
  live_call_name: string
  languages: Language[]
  location: string[]
  age: number
  verified: boolean
  is_primary: boolean
  extend_duration_1: number
  extend_duration_1_list_price: number
  extend_duration_1_discounted_price: number
  extend_duration_2: number
  extend_duration_2_list_price: number
  extend_duration_2_discounted_price: number
  extend_duration_3: number
  extend_duration_3_list_price: number
  extend_duration_3_discounted_price: number
  v_margin_pct: number
  price: number
  discount_price: number
  audio_call: AudioMeta
  action: string
}
type AudioMeta = {
  price: number
  allowed: boolean
  discount_price: number
  v_margin_pct: number
  call_packages: Array<CallPackage>
}
type CallPackage = {
  duration: number
  price: number | undefined
  discount_price: number | undefined
}
interface UserInfo {
  profile_pic: string
  user_name: string
  age: string
  gender: string
  interests: Array<string>
  location: Array<string>
  audio_call: { price: string }
  agency_id: string
  video_enabled: Boolean
  free_call_enabled: Boolean
  verified: Boolean
  created_date: string
}

type FileData = {
  [fileName: string]: File | undefined
}

type FileType = "audio" | "image" | "video"

type UserMedia = {
  type: FileType
  url: string
  is_primary?: boolean
}

type AgencList = {
  uuid: string
  name: string
  partner_type: string
}
type Error = {
  message: string
}

export default function ManageCreators(props: Props) {
  const [cityLocationList, setCityLocationList] = useState<
    { label: string; value: string }[]
  >([])
  const [files, setFiles] = useState<FileData>({})
  const [form] = Form.useForm()
  const [agencyLists, setagencyLists] = useState<AgencList[]>([])
  const [userMedia, setuserMedia] = useState<UserMedia[]>([])
  const [errors, setErrors] = useState<Error[]>([
    { message: "" },
    { message: "" },
    { message: "" },
  ])
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const [userUUid, setUserUUID] = useState(props.location.search.split("=")[1])
  const [additionalMedia, setadditionalMedia] = useState<UserMedia[]>([])
  const [flag, setFlag] = useState(true)
  const dispatch = useDispatch()
  const locationData = useSelector(
    (state: RootState) => state.locationReducer.locationList
  )

  useEffect(() => {
    dispatch(getLocationData())
    ;(async () => {
      let res = await agencyList({ type: "AUDIO_CALL" })
      let modAgency = res?.data?.map((item: AgencList) => {
        return { label: item.name, value: item.uuid }
      })
      setagencyLists(modAgency)
    })()
  }, [])

  useEffect(() => {
    if (userUUid) {
      ;(async () => {
        let res = await getLiveCallingUserData({ filters: { id: userUUid } })
        setUserInfo(res.data[0])
        if (res.data[0]) {
          form.setFieldsValue({
            user_uuid: res.data[0].user_uuid,
            user_name: res.data[0].user_name,
            languages: res.data[0].languages,
            age: res.data[0].age,
            gender: res.data[0].gender,
            interests: res.data[0].interests,
            location: res.data[0].location,
            price_per_minute: res.data[0].price_per_minute,
            verified: res.data[0].verified,
            video_enabled: res.data[0].video_enabled,
            free_call_enabled: res.data[0].free_call_enabled,
            agency_id: res.data[0].agency_id,
            extend_duration_1:
              res.data[0].audio_call?.call_packages &&
              res.data[0].audio_call?.call_packages[0]?.duration,
            extend_duration_1_list_price:
              res.data[0].audio_call?.call_packages &&
              res.data[0].audio_call?.call_packages[0]?.price,
            extend_duration_1_discounted_price:
              res.data[0].audio_call?.call_packages &&
              res.data[0].audio_call?.call_packages[0]?.discount_price,
            extend_duration_2:
              res.data[0].audio_call?.call_packages &&
              res.data[0].audio_call?.call_packages[1]?.duration,
            extend_duration_2_list_price:
              res.data[0].audio_call?.call_packages &&
              res.data[0].audio_call?.call_packages[1]?.price,
            extend_duration_2_discounted_price:
              res.data[0].audio_call?.call_packages &&
              res.data[0].audio_call?.call_packages[1]?.discount_price,
            extend_duration_3:
              res.data[0].audio_call?.call_packages &&
              res.data[0].audio_call?.call_packages[2]?.duration,
            extend_duration_3_list_price:
              res.data[0].audio_call?.call_packages &&
              res.data[0].audio_call?.call_packages[2]?.price,
            extend_duration_3_discounted_price:
              res.data[0].audio_call?.call_packages &&
              res.data[0].audio_call?.call_packages[2]?.discount_price,
            discount_price: res.data[0].audio_call?.discount_price,
            price: res.data[0].audio_call?.price,
            v_margin_pct: res.data[0].audio_call?.v_margin_pct,
          })
          setFlag(false)

          let additionaluserMedia = res.data[0].user_media.filter(
            (item: any) => item.type == "image" && item.is_primary != true
          )
          let userMedia = []
          let userMedia2 = res.data[0].user_media.filter(
            (item: any) =>
              item.type == "audio" ||
              item.is_primary == true ||
              item.type == "video"
          )
          userMedia.push(
            res.data[0].user_media.filter(
              (item: any) => item.type == "audio"
            )[0]
          )
          userMedia.push(
            res.data[0].user_media.filter(
              (item: any) => item.type == "video"
            )[0] || {}
          )
          userMedia.push(
            res.data[0].user_media.filter(
              (item: any) => item.is_primary == true
            )[0]
          )

          setadditionalMedia(additionaluserMedia)
          setuserMedia(userMedia)
        }
      })()
    }
  }, [])
  useEffect(() => {
    if (locationData) {
      let cityLocationList: { label: string; value: string }[] = []
      locationData.map(
        (location: { name: string; dhTagId: string; children: [] }) => {
          let childrenLocationObject: { label: string; value: string } = {
            label: capitalizeFirstLetter(location.name),
            value: location.dhTagId,
          }
          cityLocationList.push(childrenLocationObject)
        }
      )
      cityLocationList.sort((a, b) => a.label.localeCompare(b.label))
      setCityLocationList(cityLocationList)
    }
  }, [locationData])

  const onFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    fileType: FileType
  ) => {
    const input = event.target as HTMLInputElement
    if (input.files && input.files.length > 0) {
      setFiles(prevFiles => ({
        ...prevFiles,
        [fileType]: input.files?.[0],
      }))
    }
  }

  const handleFileUpload = async (fileType: FileType, index: number) => {
    try {
      const userId = form.getFieldValue("user_uuid")
      if (!userId) {
        toastr.error("Please provide creator id to upload files!")
        return
      }
      const file = files[fileType]
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append("file", file)
      formData.append("userId", userId)
      const response = await uploadFile(formData)
      toastr.info("Upload in progress...")
      setuserMedia(prev => {
        const newUserMedia = [...prev]
        newUserMedia[index] = {
          url: response.azureUrl,
          type: fileType,
        }
        return newUserMedia
      })
      toastr.clear()
      toastr.success("Upload successful...")
    } catch (error) {
      toastr.clear()
    }
  }
  const handleAdditionalFileUpload = async (
    fileType: FileType,
    index: number
  ) => {
    try {
      const file = files[fileType]
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append("file", file)
      const response = await uploadFile(formData)
      setadditionalMedia(prev => {
        const newUserMedia = [...prev]
        newUserMedia[index] = {
          url: response.azureUrl,
          type: fileType,
        }
        return newUserMedia
      })
    } catch (error) {}
  }

  const onSubmit = async (values: FormData) => {
    let user_media = []
    //error on media
    if (userUUid) {
      if (userMedia[2] && userMedia[2]?.url !== "") {
        userMedia[2].is_primary = true
      }
    } else {
      if (userMedia[0] && userMedia[0]?.url !== "") {
        let error = [...errors]
        error[0].message = ""
        setErrors(error)
      } else {
        let error = [...errors]
        error[0].message = "Intro file is mandatory"
        setErrors(error)
        return
      }
      if (userMedia[2] && userMedia[2]?.url !== "") {
        let error = [...errors]
        error[2].message = ""
        setErrors(error)
      } else {
        let error = [...errors]
        error[2].message = "Primary Image is mandatory"
        setErrors(error)
        return
      }

      userMedia[2].is_primary = true
    }

    values.audio_call = {
      allowed: true,
      discount_price: +values.discount_price,
      price: +values.price,
      v_margin_pct: +values.v_margin_pct,
      call_packages: [],
    }

    if (userUUid) {
      values.action = "update"
    }

    values.live_call_name = values.user_name
    if (
      values.extend_duration_1 ||
      values.extend_duration_1_list_price ||
      values.extend_duration_1_discounted_price
    ) {
      values.audio_call.call_packages.push({
        duration: values.extend_duration_1,
        price: values.extend_duration_1_list_price
          ? +values.extend_duration_1_list_price
          : undefined,
        discount_price: +values.extend_duration_1_discounted_price
          ? +values.extend_duration_1_discounted_price
          : undefined,
      })
    }
    if (
      values.extend_duration_2 ||
      values.extend_duration_2_list_price ||
      values.extend_duration_2_discounted_price
    ) {
      values.audio_call.call_packages.push({
        duration: values.extend_duration_2,
        price: values.extend_duration_2_list_price
          ? +values.extend_duration_2_list_price
          : undefined,
        discount_price: values.extend_duration_2_discounted_price
          ? +values.extend_duration_2_discounted_price
          : undefined,
      })
    }
    if (
      values.extend_duration_3 ||
      values.extend_duration_3_list_price ||
      values.extend_duration_3_discounted_price
    ) {
      values.audio_call.call_packages.push({
        duration: values.extend_duration_3,
        price: values.extend_duration_3_list_price
          ? +values.extend_duration_3_list_price
          : undefined,
        discount_price: values.extend_duration_3_discounted_price
          ? +values.extend_duration_3_discounted_price
          : undefined,
      })
    }
    user_media = userMedia.filter(n => n)
    user_media = [...user_media, ...additionalMedia]
    await upsertCalling({ ...values, user_media: user_media })
  }

  const addMore = () => {
    setadditionalMedia(prev => {
      const newAddUserMedia = [...prev]
      newAddUserMedia.push({
        type: "image",
        url: "",
      })

      return newAddUserMedia
    })
  }
  const handleToggleUpload = (e: any) => {
    if (e.target.value == "") {
      setFlag(true)
    } else {
      setFlag(false)
    }
  }
  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={11}></Col>
          <Col md={1}>
            <Button
              color="info"
              variant="contained"
              style={{
                // width: "40%",
                marginBottom: "10px",
              }}
              onClick={() => {
                props.history.push("/live/creators/browse")
              }}
              size="medium"
            >
              Back
            </Button>
          </Col>
        </Row>

        <br />
        <Form
          form={form}
          layout="vertical"
          autoComplete="false"
          onFinish={onSubmit}
        >
          <Row>
            <Col lg="6">
              <Form.Item
                label="Creator ID"
                name="user_uuid"
                rules={[{ required: true, message: "Creator ID is required" }]}
              >
                <Input
                  onChange={e => handleToggleUpload(e)}
                  type="text"
                  size="large"
                  disabled={userInfo ? true : false}
                />
              </Form.Item>
            </Col>
            <Col lg="6">
              <Form.Item
                label="Profile Name"
                name="user_name"
                rules={[
                  { required: true, message: "Profile Name is required" },
                ]}
              >
                <Input type="text" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Form.Item
                label="Languages"
                name="languages"
                rules={[
                  {
                    required: true,
                    message: "At least one language must be selected",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  size="large"
                  options={languageOptions}
                />
              </Form.Item>
            </Col>
            <Col lg="3">
              <Form.Item
                label="Age"
                name="age"
                rules={[{ required: true, message: "Age is required" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
            <Col lg="3">
              <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true, message: "Gender is required" }]}
              >
                <Select size="large" options={genderOptions} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Form.Item label="Interests" name="interests">
                <Select
                  mode="multiple"
                  size="large"
                  options={[
                    { label: "Yoga", value: "yoga" },
                    { label: "Gym", value: "gym" },
                    { label: "Fish lover", value: "fish_lover" },
                    { label: "Dog", value: "dog" },
                    { label: "Cat", value: "cat" },
                    { label: "Beach lover", value: "beach_lover" },
                    { label: "Mountain lover", value: "mountain_lover" },
                    { label: "Road trips", value: "road_trips" },
                    { label: "Casual friendship", value: "casual_friendship" },
                    {
                      label: "Casual relationship",
                      value: "casual_relationship",
                    },
                    { label: "Astrology", value: "astrology" },
                    { label: "Dancing", value: "dancing" },
                    { label: "Singing", value: "singing" },
                    { label: "Reading books", value: "reading_books" },
                    { label: "Hiking", value: "hiking" },
                    { label: "Camping", value: "camping" },
                    { label: "Cooking", value: "cooking" },
                    { label: "Beer lover", value: "beer_lover" },
                    { label: "Writing", value: "writing" },
                    { label: "Video Games", value: "video_games" },
                    { label: "Chai lover", value: "chai_lover" },
                    { label: "Coffee lover", value: "coffee_lover" },
                    { label: "Horror movies", value: "horror_movies" },
                    { label: "Romantic movies", value: "romantic_movies" },
                    { label: "Comedy movies", value: "comedy_movies" },
                    { label: "Gardening", value: "gardening" },
                    { label: "Photography", value: "photography" },
                    { label: "Shopping", value: "shopping" },
                    { label: "Football", value: "football" },
                    { label: "Cricket", value: "cricket" },
                    { label: "Hockey", value: "hockey" },
                    { label: "Swimming", value: "swimming" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg="6">
              <Form.Item
                label="Location"
                name="location"
                rules={[{ required: true, message: "Location is required" }]}
              >
                <Select size="large" options={cityLocationList} />
              </Form.Item>
            </Col>
            <Col lg="3">
              <Form.Item
                label="Verified"
                name="verified"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  size="large"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg="3">
              <Form.Item
                label="Eligible for free calls"
                name="free_call_enabled"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Select
                  size="large"
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Agency ID"
                name="agency_id"

                // rules={[{ required: true, message: "Agency ID is required" }]}
              >
                <Select
                  size="large"
                  options={agencyLists}
                  placeholder="Blank if creator is not associated with agency"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ borderColor: "#bbb" }}>
            Pricing | Audio Call
          </Divider>
          <Row>
            <Col lg="4">
              <Form.Item
                label="List Price/min (Jems)"
                name="price"
                rules={[
                  { required: true, message: "List Price/min is required" },
                ]}
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
            <Col lg="4">
              <Form.Item
                label="Verse Margin (In Percentage)"
                name="v_margin_pct"
                rules={[{ required: true, message: "This field is required" }]}
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
            <Col lg="4">
              <Form.Item
                label="Discounted price (Jems)"
                name="discount_price"
                rules={[
                  { required: true, message: "Discounted price is required" },
                ]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Form.Item
                label="Extend Duration 1"
                name="extend_duration_1"
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Select
                  size="large"
                  options={[
                    { label: "5 min", value: 5 },
                    { label: "10 min", value: 10 },
                    { label: "15 min", value: 15 },
                    { label: "30 min", value: 30 },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg="4">
              <Form.Item
                label="List price (Jems)"
                name="extend_duration_1_list_price"
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
            <Col lg="4">
              <Form.Item
                label="Discounted price (Jems)"
                name="extend_duration_1_discounted_price"
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Form.Item
                label="Extend Duration 2"
                name="extend_duration_2"
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Select
                  size="large"
                  options={[
                    { label: "5 min", value: 5 },
                    { label: "10 min", value: 10 },
                    { label: "15 min", value: 15 },
                    { label: "30 min", value: 30 },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg="4">
              <Form.Item
                label="List price (Jems)"
                name="extend_duration_2_list_price"
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
            <Col lg="4">
              <Form.Item
                label="Discounted price (Jems)"
                name="extend_duration_2_discounted_price"
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <Form.Item
                label="Extend Duration 3"
                name="extend_duration_3"
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Select
                  size="large"
                  options={[
                    { label: "5 min", value: 5 },
                    { label: "10 min", value: 10 },
                    { label: "15 min", value: 15 },
                    { label: "30 min", value: 30 },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg="4">
              <Form.Item
                label="List price (Jems)"
                name="extend_duration_3_list_price"
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
            <Col lg="4">
              <Form.Item
                label="Discounted price (Jems)"
                name="extend_duration_3_discounted_price"
                // rules={[{ required: true, message: "Gender is required" }]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ borderColor: "#bbb" }}>Media</Divider>
          <Row className="d-flex align-items-end">
            <Col md="8">
              <label className="col-form-label">Intro file (audio)</label>
              <input
                type="file"
                className="form-control"
                style={
                  errors[0].message !== "" ? { border: "1px solid red" } : {}
                }
                accept="audio/*"
                onChange={e => onFileChange(e, "audio")}
              />
              {errors[0].message !== "" ? (
                <label style={{ fontSize: "12px", color: "red" }}>
                  {errors[0].message}
                </label>
              ) : (
                <></>
              )}
            </Col>
            <Col>
              <Button
                color="primary"
                variant="contained"
                className="mx-2"
                disabled={flag}
                onClick={() => handleFileUpload("audio", 0)}
              >
                Upload
              </Button>
            </Col>

            <div>{userMedia[0]?.url && `${userMedia[0].url}`}</div>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md="8">
              <label className="col-form-label">Primary Video</label>
              <input
                type="file"
                className="form-control"
                // style={errors[1]?.message !=="" ? {border:'1px solid red'}:{}}

                onChange={e => onFileChange(e, "video")}
              />
              {/* {errors[1]?.message !=="" ? <label style={{fontSize:'12px',color:'red'}}>{errors[1]?.message}</label>:<></>} */}
            </Col>
            <Col>
              <Button
                color="primary"
                variant="contained"
                className="mx-2"
                disabled={flag}
                onClick={() => handleFileUpload("video", 1)}
              >
                Upload
              </Button>
            </Col>
            {userMedia[1]?.url && (
              <Col>
                {" "}
                <video src={userMedia[1]?.url} height="50px" width="50px" />
              </Col>
            )}

            {/* <Col>
              <Form.Item label="Is Primary" name="is_primary">
                <Switch />
              </Form.Item>
            </Col> */}
            <div>{userMedia[1]?.url && `${userMedia[1].url}`}</div>
          </Row>
          <Row className="d-flex align-items-end">
            <Col md="8">
              <label className="col-form-label">Primary Image</label>
              <input
                type="file"
                className="form-control"
                style={
                  errors[2].message !== "" ? { border: "1px solid red" } : {}
                }
                accept="image/*"
                onChange={e => onFileChange(e, "image")}
              />
              {errors[2].message !== "" ? (
                <label style={{ fontSize: "12px", color: "red" }}>
                  {errors[2].message}
                </label>
              ) : (
                <></>
              )}
            </Col>
            <Col>
              <Button
                color="primary"
                variant="contained"
                className="mx-2"
                disabled={flag}
                onClick={() => handleFileUpload("image", 2)}
              >
                Upload
              </Button>
            </Col>
            {userMedia[2]?.url && (
              <Col>
                {" "}
                <img src={userMedia[2]?.url} height="50px" width="50px" />
              </Col>
            )}

            {/* <Col>
              <Form.Item label="Is Primary" name="is_primary">
                <Switch />
              </Form.Item>
            </Col> */}
            <div>{userMedia[2]?.url && `${userMedia[2].url}`}</div>
          </Row>

          <Divider style={{ borderColor: "#bbb" }}>Additional Images</Divider>
          {additionalMedia.map((_, index) => (
            <Row className="d-flex align-items-end">
              <Col md="8">
                <label className="col-form-label">Image File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={e => onFileChange(e, "image")}
                />
              </Col>
              {_?.url && (
                <Col>
                  {" "}
                  <img src={_.url} height="50px" width="50px" />
                </Col>
              )}
              <Col>
                <Button
                  color="primary"
                  variant="contained"
                  className="mx-2"
                  disabled={flag}
                  onClick={() => handleAdditionalFileUpload("image", index)}
                >
                  Upload
                </Button>
              </Col>
              {_?.url && `${_.url}`}
            </Row>
          ))}
          <Button
            color="info"
            variant="contained"
            startIcon={<MdOutlineAdd />}
            style={{
              marginLeft: "0px",
              marginTop: "5px",
            }}
            onClick={addMore}
          >
            Add More
          </Button>
          <Row className="d-flex justify-content-center mt-2">
            <Button
              color="success"
              variant="contained"
              style={{
                width: "98%",
              }}
              size="large"
              type="submit"
            >
              SUBMIT
            </Button>
          </Row>
        </Form>
      </CardBody>
    </Card>
  )
}
