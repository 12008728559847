import { getAgencyCreators, getAgencyStats } from "appRedux/slices/agency"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Card, Col, Row } from "reactstrap"
import Auxiliary from "util/Auxiliary"
import { formatNumber, millisecondsToHHMMSS } from "util/common"
import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Button,
} from "@mui/material"
import { Pagination } from "antd"
import { MdEdit } from "react-icons/md"

export default function AgencyStats(props) {
  const dispatch = useDispatch()
  const [filters, setFilters] = useState({
    pageSize:
      props.queryParams && props.queryParams["pageSize"]
        ? props.queryParams["pageSize"]
        : 10,
    currentPage:
      props.queryParams && props.queryParams["currentPage"]
        ? props.queryParams["currentPage"]
        : 1,
  })

  const { agencyData, loading, creatorsData, creatorsTotal } = useSelector(
    state => state.agencyStats
  )
  const {
    currentUser: { data },
  } = useSelector(state => state.currentUser)

  useEffect(() => {
    dispatch(getAgencyStats(data.partnerId))
    dispatch(
      getAgencyCreators({
        partnerId: data.partnerId,
        ...filters,
      })
    )
  }, [])

  const getCreatorData = id => {
    props.history.push(`/creator/stats?userUUID=${id}`)
  }

  const handlePageChange = value => {
    let newFilters = _.cloneDeep(filters)
    newFilters["currentPage"] = value

    setFilters(newFilters)

    dispatch(
      getAgencyCreators({
        partnerId: data.partnerId,
        ...newFilters,
      })
    )
  }

  return (
    <Auxiliary loading={loading}>
      <Row>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Total Diamonds Earned</h3>
              <h4>{formatNumber(agencyData.total_diamonds_count)}</h4>
            </div>
          </Card>
        </Col>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Total Redeemable Diamonds</h3>
              <h4>{formatNumber(agencyData.total_redeemable_diamond)}</h4>
            </div>
          </Card>
        </Col>
      </Row>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="large" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>User Id</b>
                </TableCell>
                <TableCell>
                  <b>User Name</b>
                </TableCell>
                <TableCell>
                  <b>Live Call Name</b>
                </TableCell>
                <TableCell>
                  <b>Rating</b>
                </TableCell>
                <TableCell>
                  <b>Diamonds Earned</b>
                </TableCell>
                <TableCell>
                  <b>Time Spent (HH:MM:SS)</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creatorsData.map(row => {
                return (
                  <TableRow
                    style={{ cursor: "pointer" }}
                    onClick={() => getCreatorData(row.user_uuid)}
                  >
                    <TableCell>{row.user_uuid}</TableCell>
                    <TableCell>{row.user_name}</TableCell>
                    <TableCell>{row.live_call_name}</TableCell>
                    <TableCell>{row.avg_rating}</TableCell>
                    <TableCell>
                      {formatNumber(row.total_diamonds_count)}
                    </TableCell>
                    <TableCell>
                      {millisecondsToHHMMSS(row.total_time_spent)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="d-flex justify-content-end pb-3">
          <Pagination
            style={{ marginTop: "1rem" }}
            onChange={handlePageChange}
            current={filters.currentPage}
            total={creatorsTotal < 10000 ? creatorsTotal : 10000}
            pageSize={filters.pageSize}
            showSizeChanger={false}
          />
        </div>
      </Paper>
    </Auxiliary>
  )
}
