import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "react-drawer/lib/react-drawer.css"
import { Link } from "react-router-dom"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "../../assets/images/favicon.ico"
import QueryString from "query-string"
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../appRedux/actions/layout.action"

const Header = props => {
  const [searchType, handleSearchType] = useState("content_uuid")
  const [searchText, handleSearchText] = useState("")
  const [filters, handleFieldChange] = useState({
    searchType: "content_uuid",
    searchText: null,
  })
  const [clear, setClear] = useState(false)
  const [isSearchTypeChange, handleSearchTypeChange] = useState(false)
  if (props.sideBarToggel) {
    aToggle()
  }
  const _handleFilterChange = (value, type) => {
    if (type === "searchType") {
      setClear(true)
      handleFieldChange({ searchType: value || "", searchText: "" })
      handleSearchType(value)
      handleSearchTypeChange(true)
    }
    if (type === "searchText") {
      setClear(false)
      handleSearchText(value)
      handleFieldChange({ ...filters, searchText: value })
      searchContent(filters.searchType, value)
    }
  }

  const searchContent = (type, value) => {
    switch (type) {
      case "content_uuid":
        props.history.push(`/content/manage?contentUUID=${value}`)
        return <Link to={`/content/manage?contentUUID=${value}`} />
        break
      case "user_uuid":
        props.history.push(`/appuser/details?userUuid=${value}`)
        return <Link to={`/appuser/details?userUuid=${value}`} />
        break
      case "challenge_id":
        props.history.push(`/challenge/manage?challengeUuid=${value}`)
        return <Link to={`/challenge/manage?challengeUuid=${value}`} />
        break
      case "audio_id":
        props.history.push(`/audio/manage?audioId=${value}`)
        return <Link to={`/audio/manage?audioId=${value}`} />
        break
      case "zone_id":
        props.history.push(`/zone/manage?zoneUuid=${value}`)
        return <Link to={`/zone/manage?zoneUuid=${value}`} />
        break
      case "image_id":
        props.history.push(`/image/manage?imageUuid=${value}`)
        return <Link to={`/image/manage?imageUuid=${value}`} />
        break
      default:
        break
    }
  }

  const handleClear = () => {
    setClear(true)
    handleSearchText("")
    handleFieldChange({ ...filters, searchText: "" })
  }

  const searchTextParams = !_.isEmpty(props.queryParams)
    ? props.queryParams.contentUUID ||
      props.queryParams.userUuid ||
      props.queryParams.challengeUuid ||
      props.queryParams.audioId ||
      props.queryParams.zoneUuid ||
      props.queryParams.imageUuid
    : ""

  const searchTypeParams =
    !isSearchTypeChange && !_.isEmpty(props.queryParams)
      ? (props.queryParams.contentUUID && "content_uuid") ||
        (props.queryParams.userUuid && "user_uuid") ||
        (props.queryParams.challengeUuid && "challenge_id") ||
        (props.queryParams.audioId && "audio_id") ||
        (props.queryParams.zoneUuid && "zone_id") ||
        (props.queryParams.imageUuid && "image_id")
      : filters?.searchType || ""

  useEffect(() => {
    let page = props?.history?.location?.pathname.split("/")[1]
    if (page === "user" || page === "appuser") {
      handleFieldChange({ ...filters, searchType: "user_uuid" })
    } else if (page === "content") {
      handleFieldChange({ ...filters, searchType: "content_uuid" })
    } else if (page === "challenge") {
      handleFieldChange({ ...filters, searchType: "challenge_id" })
    } else if (page === "audio") {
      handleFieldChange({ ...filters, searchType: "audio_id" })
    } else if (page === "zone") {
      handleFieldChange({ ...filters, searchType: "zone_id" })
    } else if (page === "image") {
      handleFieldChange({ ...filters, searchType: "image_id" })
    }
  }, [handleFieldChange])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  function aToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.add("sidebar-enable")
    } else {
      body.classList.add("vertical-collpsed")
      body.classList.add("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex w-100">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>
            </div>

            <div className="d-flex" style={{ width: "80%" }}>
              <button
                type="button"
                onClick={() => {
                  tToggle()
                }}
                className="btn btn-sm px-3 font-size-16 header-item "
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
              <div
                className="app-search d-flex w-100"
                style={{ width: "100% !important" }}
              >
                {props.currentUser.jotUserType != 60 ? (
                  <div className="position-relative w-100 d-flex">
                    <select
                      placeholder="Search Type"
                      style={{
                        background: "ghostwhite",
                        width: "10%",
                        paddingLeft: "10px",
                        border: "unset",
                      }}
                      name="searchType"
                      value={searchTypeParams || filters?.searchType || ""}
                      onChange={event =>
                        _handleFilterChange(event?.target?.value, "searchType")
                      }
                    >
                      <option value="audio_id">Audio</option>
                      <option value="challenge_id">Challenge</option>
                      <option value="content_uuid">Content</option>
                      <option value="image_id">Image</option>
                      <option value="user_uuid">User</option>
                      <option value="zone_id">Zone</option>
                    </select>
                    <input
                      type="text"
                      style={{
                        background: "ghostwhite",
                        width: "40%",
                        paddingLeft: "10px",
                        marginLeft: "10px",
                        border: "unset",
                      }}
                      className="search-box"
                      placeholder={"Search Value"}
                      disabled={!filters?.searchType}
                      value={
                        clear
                          ? ""
                          : searchTextParams || filters?.searchText || ""
                      }
                      key={filters?.searchType || ""}
                      onChange={e =>
                        _handleFilterChange(e.target.value.trim(), "searchText")
                      }
                      allowClear={true}
                      name="searchText"
                    />
                    <button
                      className="close-icon"
                      type="reset"
                      onClick={handleClear}
                    ></button>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-end flex-fill">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
              <ProfileMenu />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = (state, ownProps) => {
  const layoutType = "vertical"
  const showRightSidebar = true
  const leftMenu = true
  const leftSideBarType = "default"

  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    queryParams: _.get(ownProps, "location.search")
      ? QueryString.parseUrl(_.get(ownProps, "location.search")).query
      : undefined,
  }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(Header)
