import React, { useEffect, useState } from "react"
import Select from "react-select"
import Switch from "react-switch"
import _ from "lodash"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { PERMISSIONS_ARRAY } from "../../constants/uiConstants"
import { Offsymbol, OnSymbol } from "util/switchButtonUtil"
import CustomSelect from "../../components/CustomSelect/customSelect"
import {
  getAllSources,
  getSourcesForSelectedType,
} from "clientServices/cmsUserService"
import MultiSelectSort from "components/SortableSelect/SortableSelect"

const UpsertUser = props => {
  const userStatus = props.data && props.data.userStatus === 0 ? false : true
  const [agency, setAgency] = useState(null)
  const [switch1, setSwitch] = useState(userStatus)
  const [jotPermissions, setJotPermissions] = useState([])
  const [jotRevokedPermissions, setJotRevokedPermissions] = useState([])
  const [jotRoles, setJotRoles] = useState([])
  const [sources, setSources] = useState([])
  const [sourceType, setSourceType] = useState(null)
  const [jotUserType, setUserType] = useState(null)
  const [deskType, setDeskType] = useState(null)
  const [clicked, setClicked] = useState(false)
  const [allSources, setAllSources] = useState([])
  const [availableSource, setAvailableSource] = useState([])
  const [deleteDesk, setDeleteDesk] = useState(false)

  useEffect(async () => {
    const result = await getAllSources()
    const allSources = result.map(source => {
      return {
        label: source.display_name,
        value: source.source_id,
        type: source.type,
      }
    })
    setAllSources(allSources)
    setJotPermissions(props.data.jotPermissions)
    setJotRevokedPermissions(props.data.jotRevokedPermissions)
  }, [])

  const userOptions = [
    {
      label: "Super Admin",
      value: 1,
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "Admin",
      value: 2,
      disabled: props.currentUser.jotUserType > 1,
    },
    { label: "Content Operations Manager", value: 3 }, // Create Update Challem, audio and content
    { label: "Moderator", value: 4 }, // Requires permissions to be updated in the users table and same reads to be handled at routes
    { label: "Ads Ops", value: 6 }, // For source Type and sponsor text in challenge and audio
    { label: "Internal Moderator", value: 7 },
    {
      label: "Community Head",
      value: 11,
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "Community Lead",
      value: 12,
      disabled: ![1, 11].includes(props.currentUser.jotUserType),
    },
    {
      label: "Community Account Manager",
      value: 13,
      disabled: ![1, 11, 12].includes(props.currentUser.jotUserType),
    },
    {
      label: "Content Head",
      value: 21,
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "Josh Manager",
      value: 33,
      disabled: ![1, 11, 12, 31, 32].includes(props.currentUser.jotUserType),
    },
    {
      label: "Josh Lead",
      value: 32,
      disabled: ![1, 11, 12, 31].includes(props.currentUser.jotUserType),
    },
    {
      label: "Josh Head",
      value: 31,
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "Issue Manager",
      value: 50,
      disabled: props.currentUser.jotUserType > 2,
    },
    {
      label: "Partner Agency",
      value: 60,
      disabled: props.currentUser.jotUserType > 2,
    },
  ]

  const labelling = props.data?.source?.LABELLING
    ? `LABELLING || Desk ID:- ${props.data.source.LABELLING}`
    : "LABELLING"

  const moderation = props.data?.source?.MODERATION
    ? `MODERATION || Desk ID:- ${props.data.source.MODERATION}`
    : "MODERATION"
  const taxonomy = props.data?.source?.TAXONOMY
    ? `TAXONOMY || Desk ID:- ${props.data.source.TAXONOMY}`
    : "TAXONOMY"
  const taxonomyQc = props.data?.source?.TAXONOMY_QC
    ? `TAXONOMY QC || Desk ID:- ${props.data.source.TAXONOMY_QC}`
    : "TAXONOMY QC"
  const taxonomyInternalQc = props.data?.source?.TAXONOMY_INTERNAL_QC
    ? `TAXONOMY INTERNAL QC || Desk ID:- ${props.data.source.TAXONOMY_INTERNAL_QC}`
    : "TAXONOMY INTERNAL QC"
  const trending = props.data?.source?.TRENDING
    ? `TRENDING || Desk ID:- ${props.data.source.TRENDING}`
    : "TRENDING"

  const deskTypeOptions = [
    { label: labelling, value: "LABELLING" },
    { label: moderation, value: "MODERATION" },
    { label: taxonomy, value: "TAXONOMY" },
    { label: "TAXONOMY STAGE 1", value: "TAXONOMY_STAGE_1" },
    { label: "TAXONOMY STAGE 2", value: "TAXONOMY_STAGE_2" },
    { label: "TAXONOMY STAGE 3", value: "TAXONOMY_STAGE_3" },
    { label: taxonomyQc, value: "TAXONOMY_QC" },
    { label: taxonomyInternalQc, value: "TAXONOMY_INTERNAL_QC" },
    { label: "ZONE APPROVAL", value: "ZONE_APPROVAL" },
    { label: trending, value: "TRENDING" },
    { label: "REVIEW WEBSITE LINK", value: "REVIEW_WEBSITE_LINK" },
    { label: "ZONE REVIEW", value: "ZONE_REVIEW" },
    { label: "REVIEW INTERESTS", value: "REVIEW_INTERESTS" },
    { label: "SEED INTERESTS", value: "SEED_INTERESTS" },
  ]

  const permissionArray = [
    ...PERMISSIONS_ARRAY,
    {
      label: "Tag Content Admin",
      value: "TAG_CONTENT_ADMIN",
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "Community Manager",
      value: "COMMUNITY_MANAGER",
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "View Real Stats",
      value: "VIEW_REAL_STATS",
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "Manage CMS Users",
      value: "MANAGE_CMS_USERS",
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "Boost Content",
      value: "BOOST_CONTENT",
      disabled: props.currentUser.jotUserType > 1,
    },
    {
      label: "QC Search By ID",
      value: "QC_SEARCH_BY_ID",
      disabled: !props.currentUser.jotRoles?.includes("ADMIN"),
    },
  ]

  const roles =
    props &&
    props.roleData &&
    props.roleData.map(role => {
      if (
        role.role === "ADMIN" &&
        !props.currentUser?.jotRoles?.includes("SUPER_ADMIN")
      ) {
        return { label: role.role, value: role.role, disabled: false }
      } else {
        return { label: role.role, value: role.role }
      }
    })

  const filterdPermissions = PERMISSIONS_ARRAY.map(permission => {
    if (
      (permission.value === "UPDATE_USER_AGENCY" ||
        permission.value === "QC_SEARCH_BY_ID" ||
        permission.value === "NOTIF_WORTHY_CONTENT_DEACTIVATE_PERMISSION" ||
        permission.value === "EB_CONTENT_DEACTIVATION_PERMISSION" ||
        permission.value === "EDIT_GIFTS" ||
        permission.value === "VIEW_GIFTS" ||
        permission.value === "EDIT_PACKAGE" ||
        permission.value === "VIEW_PACKAGE" ||
        permission.value === "ACTIVATE_BANNED_CONTENT" ||
        permission.value === "EDIT_CONTENT_PRIVATE_MODE" ||
        permission.value === "EDIT_ALLOW_CONTENT_INFO" ||
        permission.value === "RESTORE_CONTENT" ||
        permission.value === "ACTIVATE_COPYRIGHT_CONTENT" ||
        permission.value === "ACTIVATE_BANNED_CONTENT" ||
        permission.value === "VIEW_ORIGINAL_VIDEO" ||
        permission.value === "RATING" ||
        permission.value === "MARK_AS_SEED" ||
        permission.value === "AUDIT_CMS_USER" ||
        permission.value === "VIEW_BLOCKED_CONTENT") &&
      !props.currentUser?.jotRoles?.includes("SUPER_ADMIN")
    ) {
      return { ...permission, disabled: true }
    } else {
      return permission
    }
  })

  const _onSubmit = formData => {
    formData.creatorId = _.get(props, "data.userId")
      ? _.get(props, "data.userId")
      : ""
    formData.userStatus = switch1 ? 1 : 0
    formData.jotRoles = !_.isEmpty(jotRoles) ? JSON.stringify(jotRoles) : null
    formData.agency = agency ? agency.value : null
    formData.jotUserType = jotUserType
    formData.deskType = deskType ? deskType.value : null
    formData.email = formData.email.toLowerCase()
    formData.sources = sources.map(source => source.value)
    formData.deleteDesk = deleteDesk
    var data = _.omitBy(formData, _.isNil)
    data.jotPermissions = !_.isEmpty(jotPermissions)
      ? JSON.stringify(jotPermissions)
      : JSON.stringify([])
    data.jotRevokedPermissions = !_.isEmpty(jotRevokedPermissions)
      ? JSON.stringify(jotRevokedPermissions)
      : JSON.stringify([])
    props.onSubmit(data)
  }

  const _setPermissions = val => {
    const perm = val.map(element => {
      return element.value
    })
    setJotPermissions(perm)
  }

  const _setRevokedPermissions = val => {
    const perm = val.map(element => {
      return element.value
    })
    setJotRevokedPermissions(perm)
  }

  const _setJotRoles = val => {
    const perm = val.map(element => {
      return element.value
    })
    setJotRoles(perm)
  }

  const _getElement = (array, val) => {
    let element
    array.forEach(option => {
      if (option.value === val) {
        element = option
      }
    })
    return element
  }

  const _getAgency = (array, val) => {
    let element
    array.forEach(option => {
      if (option.constantVal === val) {
        element = option
      }
    })
    if (element) {
      return { label: element.constantLabel, value: element.constantVal }
    }
  }

  const _getPermissions = perms => {
    let array = []
    permissionArray.forEach(p => {
      if (perms.includes(p.value)) {
        array.push(p)
      }
    })
    return array
  }

  const _getOptions = options => {
    return options.filter(option => !option.disabled)
  }

  const _handleDeskTypeChange = async value => {
    setDeskType(value)
    setSources([])

    const availableSource = allSources.filter(
      source => source.type === value.value
    )
    setAvailableSource(availableSource)
    const deskId = props.data?.source?.[value.value]
    if (deskId) {
      const sources = []
      let result = await getSourcesForSelectedType(deskId)
      result.forEach(sourceId => {
        allSources.forEach(source => {
          if (source.value === sourceId) {
            sources.push(source)
          }
        })
      })
      setSources(sources)
    }
  }

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardTitle className="p-3 pb-0">
            {props.formAction === "create"
              ? "Create CMS User"
              : "Update CMS User"}
          </CardTitle>
          <CardBody>
            <AvForm onValidSubmit={(e, values) => _onSubmit(values)}>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-2">
                    <Label htmlFor="name" className="col-lg-2">
                      Full Name
                    </Label>
                    <AvField
                      name="name"
                      type="text"
                      defaultValue={props.data && props.data.name}
                      errorMessage="Provide Name"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-2">
                    <Label htmlFor="email" className="col-lg-2">
                      Email
                    </Label>
                    <AvField
                      name="email"
                      placeholder="Enter Valid Email"
                      type="email"
                      errorMessage="Invalid Email"
                      validate={{
                        required: { value: true },
                        email: { value: true },
                      }}
                      defaultValue={props.data && props.data.email}
                      disabled={props.formAction === "create" ? false : true}
                    />
                  </FormGroup>
                </Col>
                {props.formAction === "create" && (
                  <Col lg="6">
                    <FormGroup className="mb-2">
                      <Label htmlFor="password" className="col-lg-2">
                        Password
                      </Label>
                      <AvField
                        name="password"
                        type="password"
                        placeholder="Password"
                        errorMessage="Enter password"
                        autoComplete="off"
                        validate={{
                          required: {
                            value: props.formAction === "create" ? true : false,
                          },
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col lg="6">
                  <FormGroup className="mb-2">
                    <Label htmlFor="jotUserType" className="col-lg-2">
                      User Type
                    </Label>
                    <div lg="10" onClick={() => setClicked(true)}>
                      <CustomSelect
                        isMulti={false}
                        defaultValue={
                          props.data &&
                          props.data.jotUserType &&
                          _getElement(userOptions, props.data.jotUserType)
                        }
                        options={_getOptions(userOptions)}
                        onChange={val => setUserType(val.value)}
                        isValid={jotUserType || !clicked}
                        errorMessage="Select User Type"
                      />
                    </div>
                  </FormGroup>
                </Col>
                {(props.formAction === "create" ||
                  props.currentUser?.permissions?.includes(
                    "UPDATE_USER_AGENCY"
                  )) && (
                  <Col lg="6">
                    <FormGroup className="mb-2">
                      <Label htmlFor="agency" className="col-lg-2">
                        Agency
                      </Label>
                      <Select
                        type="select"
                        name="agency"
                        onChange={val => setAgency(val)}
                        options={props.agencyList.map(agency => ({
                          label: agency.constantLabel,
                          value: agency.constantVal,
                        }))}
                        defaultValue={
                          props.data &&
                          props.data.agency &&
                          _getAgency(props.agencyList, props.data.agency)
                        }
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col lg="6">
                  <FormGroup className="mb-2">
                    <Label htmlFor="jotPermissions">Add Permissions</Label>
                    <Select
                      isMulti={true}
                      onChange={val => _setPermissions(val)}
                      defaultValue={
                        props.data &&
                        props.data.jotPermissions &&
                        _getPermissions(props.data.jotPermissions)
                      }
                      options={filterdPermissions}
                      isOptionDisabled={option => option.disabled}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-2">
                    <Label htmlFor="jotRevokedPermissions">
                      Revoke Permissions
                    </Label>
                    <Select
                      isMulti={true}
                      onChange={val => _setRevokedPermissions(val)}
                      defaultValue={
                        props.data &&
                        props.data.jotRevokedPermissions &&
                        _getPermissions(props.data.jotRevokedPermissions)
                      }
                      options={filterdPermissions}
                      isOptionDisabled={option => option.disabled}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-2">
                    <Label htmlFor="roles" className="col-lg-2">
                      Roles
                    </Label>
                    <Select
                      isMulti={true}
                      onChange={val => _setJotRoles(val)}
                      defaultValue={
                        props.data &&
                        props.data.jotRoles &&
                        props.data.jotRoles.map(role => {
                          return { label: role, value: role }
                        })
                      }
                      options={roles}
                      isOptionDisabled={option => option.disabled}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-2">
                    <Label htmlFor="agency" className="col-lg-2">
                      Desk Type
                    </Label>
                    <Select
                      type="select"
                      name=""
                      value={deskType}
                      onChange={value => {
                        setSourceType(value.value)
                        _handleDeskTypeChange(value)
                      }}
                      options={deskTypeOptions}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-2">
                    <Label htmlFor="sourceId" className="col-lg-2">
                      Sources
                    </Label>
                    <MultiSelectSort
                      options={availableSource}
                      onChange={value => {
                        setSources(value)
                      }}
                      disabled={!deskType}
                      value={sources}
                    />
                  </FormGroup>
                </Col>
                {sourceType && (
                  <Col lg="6">
                    <FormGroup className="mb-2">
                      <Label htmlFor="userStatus" className="col-lg-6">
                        Delete <b>{sourceType}</b> Desk
                      </Label>
                      <Col lg="10">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          onChange={() => setDeleteDesk(!deleteDesk)}
                          checked={deleteDesk}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                )}

                {props.formAction != "create" &&
                  props.currentUser.permissions &&
                  props.currentUser.permissions.includes("AUDIT_CMS_USER") && (
                    <Col lg="6">
                      <FormGroup className="mb-2">
                        <Label htmlFor="userStatus" className="col-lg-2">
                          Status
                        </Label>
                        <Col lg="10">
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#626ed4"
                            onChange={() => setSwitch(!switch1)}
                            checked={switch1}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  )}
              </Row>
              <div className="d-flex justify-content-end">
                <Button type="submit" color="primary" className="mx-2">
                  {props.formAction === "create" ? "Create" : "Update"}
                </Button>
                <Button color="danger" onClick={props.onCancel}>
                  Cancel
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default UpsertUser
