import React, { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { connect } from "react-redux"
import Axios from "axios"
import QueryString from "query-string"
import _ from "lodash"
import { Col, Container, Row, Table } from "reactstrap"
import { Tooltip } from "antd"
import { diamondsByUsers, getLiveCallingUserData, totaldurationbyuseruuid } from "clientServices/liveCallingService"
import CallingCard from "./CallingCard"
import { Button, Chip, Stack } from "@mui/material"
import { RouteComponentProps, withRouter } from "react-router-dom"
import Chips from "components/CommonFilter/chips"

interface Props extends RouteComponentProps {
  queryParams: { [key: string]: string | number }
  loading: boolean
}
type Filter={
  pageSize:string | number,
  currentPage:string | number,
  platformNotExist:string,
  agency_id:string
}

interface Creator {
  user_uuid: string;
  totaldiamond?: number; // Optional because it's added later
  [key: string]: any; // For other dynamic properties
}
const BrowseCreators = ({ queryParams, history }: Props) => {
  const [filters, setFilters] = useState<Filter>({
    pageSize:
      queryParams && queryParams["pageSize"] ? queryParams["pageSize"] : 10,
    currentPage:
      queryParams && queryParams["currentPage"]
        ? queryParams["currentPage"]
        : 1,
    platformNotExist: "Y",
    agency_id:""

  })

  const [ showFilter,setShowFilter]=useState(false)

  const[loading, setLoading]=useState(false)

  const[env, setEnv]=useState("")
  const [userData, setuserData] = useState<Creator[]>([])
  useEffect(() => {
    ;(async () => {
      let res = await getLiveCallingUserData({ filters })
      
      setEnv(res.user_env)
      setLoading(false)
let listofusers=res.data?.filter((item:any) => item.user_uuid !=null).map((items:any)=>items.user_uuid
);

let totaldurationCountByUseruuid:any= await totaldurationbyuseruuid({user_uuids:listofusers})
// let diamondsPerUser:any=await diamondsByUsers(listofusers);

const updatedUserData = res.data.map((user: Creator) => ({
  ...user,
  totalDuration: totaldurationCountByUseruuid[user.user_uuid] || 0, // Set totaldiamond, default to 0 if not found
}));
setuserData(updatedUserData)


// setuserDiamondData(diamonsperuser)
})()
  }, [loading])

  const handleLoading=()=>{
    setLoading(true)
  }
  const onCreate = () => {
    history.push(`/live/creators/manage`)
  }
  const onEdit = (itemId:string) => {
    history.push(`/live/creators/manage?creator_id=`+itemId)
  }

  const onInfo = (itemInfo: string) => {
    history.push(`/live/creators/info?creator_uuid=` + itemInfo)
  }
  const _closeFilters = async() => {
    let res = await getLiveCallingUserData({ filters })
    setuserData(res.data)
    setEnv(res.user_env)
    setShowFilter(false)
    
  }
  // _removeFilter = filter => {
  //   const newFilters = {
  //     ...this.state.filters,
  //     [filter]: audioCallingFilters[filter].defaultValue,
  //   }

  //   this.setState(
  //     {
  //       filters: newFilters,
  //     },
  //     async () => {
  //       let storiesData = await getStoriesData()
  //       this.setState({
  //         storiesData,
  //       })
  //     }
  //   )
  // }
  const _handleFilterChange = (value:string, type:keyof Filter) => {
    
    const newFilters = _.cloneDeep(filters)
    newFilters[type] = value

    setFilters(newFilters)
   
  }
  const _toggleFilters = () => {
    setShowFilter(!showFilter)
   
  }
  
  return (
    <div>
      <Row>
      <Chips
                showFilters={showFilter}
                filters={filters}
                search={_closeFilters}
                // removeFilter={_removeFilter}
                handleFilterChange={_handleFilterChange}
                type="audio_live_calling"
                toggleFilters={_toggleFilters}
              />
      </Row>
      <Row>
        <div className="d-flex justify-content-end" style={{marginBottom:'10px'}}>
          <Button
            onClick={onCreate}
            role="button"
            color="success"
            variant="contained"
          >
            Create
          </Button>
        </div>
        <br />
        {userData.map((item: any) => (
          <Col md="3">
            <CallingCard  env={env} item={item} onInfo={onInfo} onEdit={onEdit} handleLoading={handleLoading}></CallingCard>
          </Col>
        ))}{" "}
      </Row>
    </div>
  )
}

export default withRouter(BrowseCreators)
