import Axios from "axios"
import toastr from "toastr"

export const requestAgencyStats = async payload => {
  try {
    let data = await Axios.get(`/api/agency/stats?partnerId=${payload}`)
    return data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}

export const requestAgencyCreators = async payload => {
  const partnerId = payload["partnerId"]
  const offset = payload["currentPage"] ? payload["currentPage"] - 1 : 0
  const limit = payload["pageSize"] ? payload["pageSize"] : 10
  try {
    let data = await Axios.get(
      `/api/agency/creators?partnerId=${partnerId}&offset=${offset}&limit=${limit}`
    )
    return data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}
export const requestAgencyInvoice = async payload => {
  try {
    let data = await Axios.get(`/api/agency/invoice?partnerId=${payload}`)
    return data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}

export const requestDownloadInvoice = async payload => {
  try {
    let data = await Axios.get(
      `/api/agency/invoice/download?location=${payload.location}&invoiceId=${payload.invoiceId}`
    )
    return data
  } catch (error) {
    toastr.error(error)
    throw error
  }
}
