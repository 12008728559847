import { getAgencyCreators, getAgencyStats } from "appRedux/slices/agency"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Card, Col, Row } from "reactstrap"
import Auxiliary from "util/Auxiliary"
import { formatNumber, millisecondsToHHMMSS, msToHMS } from "util/common"
import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  Button,
} from "@mui/material"
import { Pagination } from "antd"
import { MdEdit } from "react-icons/md"
import {
  diamondsByUsers,
  getLiveCallingLogData,
  getLiveCallingUserData,
} from "clientServices/liveCallingService"

export default function CreatorStats(props) {
  const dispatch = useDispatch()
  const [filters, setFilters] = useState({
    pageSize:
      props.queryParams && props.queryParams["pageSize"]
        ? props.queryParams["pageSize"]
        : 10,
    currentPage:
      props.queryParams && props.queryParams["currentPage"]
        ? props.queryParams["currentPage"]
        : 1,
  })
  const [userUUid, setUserUUID] = useState(props.location.search.split("=")[1])
  const [userInfo, setUserInfo] = useState(null)
  const [diamondInfo, setDiamondInfo] = useState(null)
  const [userInfoLog, setUserInfoLog] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const _getModerationImage = props => {
    return userInfo?.profile_pic
  }

  useEffect(() => {
    ;(async () => {
      let res = await getLiveCallingUserData({ filters: { id: userUUid } })
      setUserInfo(res.data[0])
      let log = await getLiveCallingLogData({
        filters: { id: userUUid, currentPage },
      })
      setUserInfoLog({
        ...log.data,
        ...{ total: log.total },
        ...{ logData: log.itemData?.data },
      })
      let diamondsPerUser = await diamondsByUsers([userUUid])
      setDiamondInfo(diamondsPerUser[userUUid])
    })()
  }, [loading])

  const handleModal = status => {
    setShowAddVideosPopup(status)
  }
  const _onPageChange = async value => {
    setCurrentPage(value)
    let log = await getLiveCallingLogData({
      filters: { id: userUUid, currentPage: value },
    })
    setUserInfoLog({
      ...log.data,
      ...{ total: log.total },
      ...{ logData: log.itemData?.data },
    })
  }
  return (
    <Auxiliary loading={loading}>
      <Row>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Total Diamonds Earned</h3>
              <h4>{formatNumber(diamondInfo?.total_diamonds_count)}</h4>
            </div>
          </Card>
        </Col>
        <Col lg="6">
          <Card className="p-3">
            <div className="d-flex align-items-center flex-column">
              <h3>Today Earning Diamonds</h3>
              <h4>{formatNumber(diamondInfo?.today_earning || 0)}</h4>
            </div>
          </Card>
        </Col>
      </Row>

      <table className="creatorTable">
        <thead>
          <tr className="tableRow">
            <th>Date</th>
            <th>Time</th>
            <th>Type Of Call</th>
            <th>Caller Id</th>
            <th>Call Id</th>
            <th>Duration of Call</th>
            <th>Free Calls</th>
            <th>Call end Reason</th>
            <th>Rating</th>
          </tr>
        </thead>
        <tbody>
          {userInfoLog?.logData?.map(items => (
            <tr className="tableRow">
              {" "}
              <td>
                {items?.created_date
                  ?.split("T")[0]
                  .split("-")
                  .reverse()
                  .join("/")}
              </td>
              <td>{items?.created_date?.split("T")[1].split("+")[0]}</td>
              <td>{items.comm_type}</td>
              <td>{items.caller_id}</td>
              <td>{items.id}</td>
              <td>{items.duration ? msToHMS(items.duration) : "0:0:0"}</td>
              <td>{items.fc_unit}</td>
              <td>{items.end_reason || "-"}</td>
              <td>{items.rating || "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-end">
        <Pagination
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          onChange={_onPageChange}
          defaultCurrent={currentPage}
          total={userInfoLog?.total ? userInfoLog?.total : 10}
          pageSize={10}
          showSizeChanger={false}
        />
      </div>
    </Auxiliary>
  )
}
